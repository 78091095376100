import React, { Component } from 'react';
import { getFetch, deleteFetch } from '../helpers/request';
import './FeedListing.css';
import '@shopify/polaris/dist/styles.css'
import { Card, DataTable, Button, Link, EmptyState, Banner, Tabs,
  Loading, DisplayText, Stack, Spinner, Modal, Layout, Badge, List, TextStyle } from '@shopify/polaris';
import PropTypes from "prop-types";
import emptyStateImage from '../images/product.svg';
import CopyImage from '../images/Copy.png';
import BlogImage1 from '../images/Blog1.png';
import BlogImage2 from '../images/Blog2.png';
import ProductImage1 from '../images/Product1.png';
import ProductImage2 from '../images/Product2.png';
import ThemeImage1 from '../images/Theme1.png';
import ThemeImage2 from '../images/Theme2.png';


interface FeedListingProps {
  selectedTabIndex?: number
}

interface FeedListingState {
  themeSettingsURL: string,
  themeSupportsAppExtensions: boolean,
  embedFeedId: string,
  copyCodeText: string,
  feeds: Array<any>,
  hasLoaded: boolean,
  errorLoading: boolean,
  isRefreshing: boolean,
  showBanner: boolean,
  shouldOpenDeleteModal: boolean,
  deleteIndex: number,
  deleteLoading: boolean,
  selectedTabIndex: number,
  openEmbedModal: boolean
}

class FeedListing extends Component <FeedListingProps, FeedListingState> {
  
  static contextTypes = { polaris: PropTypes.object, };

  constructor(props: FeedListingProps) {
    super(props);
    this.state = { 
      themeSettingsURL: '',
      embedFeedId: '',
      copyCodeText: 'Copy Code',
      openEmbedModal: false,
      themeSupportsAppExtensions: false,
      feeds: [],
      hasLoaded: false,
      errorLoading: false,
      isRefreshing: false,
      showBanner: true,
      shouldOpenDeleteModal: false,
      deleteIndex: 0,
      deleteLoading: false,
      selectedTabIndex: this.props.selectedTabIndex === undefined ? 0 : this.props.selectedTabIndex!
    };
  }

  componentDidMount() {
    getFetch('/app/api/feeds', {})
    .then((res:any) => res.json()
    ).then(data => { 
      this.setState({ themeSupportsAppExtensions: data.theme_supports_app_extensions});
      this.setState({ feeds: data.data });
      this.setState({ hasLoaded: true });
      this.setState({ themeSettingsURL: 'https://' + data.shopify_url +'/admin/themes/' + data.theme_id + '/editor?context=apps'});
    }).catch(err => {
      this.setState({ hasLoaded: true });
      this.setState({ errorLoading: true });
    });
  }

  componentWillUnmount() {

  }

  renderUsernameBadges = (index:number) => {
    return this.state.feeds[index].usernames.map((tag:string) => {
      return(<Badge key={tag + '-' + index}>{'@'+tag}</Badge>)
    });
  }

  renderHashtagBadges = (index:number) => {
    return this.state.feeds[index].hashtags.map((tag:string) => {
      return(<Badge key={tag + '-' + index}>{'#'+tag}</Badge>)
    });
  }

  renderLocationBadges = (index:number) => {
    return this.state.feeds[index].locations.map((tag:any) => {
      return(<Badge key={tag + '-' + index}>{tag.label}</Badge>)
    });
  }

  openDeleteModal = (index:number) => {
    this.setState({deleteIndex: index});
    this.setState({shouldOpenDeleteModal:true});
  }

  closeDeleteModal = () => {
    this.setState({shouldOpenDeleteModal:false});
  }

  confirmDelete = () => {
    this.setState({deleteLoading: true})
    let deleteIndex = this.state.deleteIndex;
    let id = this.state.feeds[deleteIndex].uuid;
    let newFeeds = this.state.feeds.filter((feed)=> {
      return feed.uuid !== id
    });
    deleteFetch('/app/api/feeds/', { id: id })
    .then((res:any) => res.json()
    ).then(data => {
      this.setState({deleteLoading: false})
      this.closeDeleteModal();
      this.setState({feeds: newFeeds});
    }).catch(err => {
      this.setState({deleteLoading: false})
    });
    
  }

  copyToClipboard = (text:string) => {
      if ( (window as any).clipboardData &&  (window as any).clipboardData.setData) {
          // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
          return (window as any).clipboardData.setData("Text", text);
      }
      else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
          var textarea = document.createElement("textarea");
          textarea.textContent = text;
          textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
          document.body.appendChild(textarea);
          textarea.select();
          try {
              return document.execCommand("copy");  // Security exception may be thrown by some browsers.
          }
          catch (ex) {
              console.warn("Copy to clipboard failed.", ex);
              return false;
          }
          finally {
              document.body.removeChild(textarea);
          }
      }
  }

  copyCodeToClipboard = () => {
    var textField = document.createElement('textarea');
    let dataId = this.state.embedFeedId.replace(/-/g,'');
    this.copyToClipboard('<div class="vw-instagram-slider-feed" data-id="' + dataId + '"></div>');
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    this.setState({copyCodeText: "Copied!"})
    setTimeout(() => {
      this.setState({copyCodeText: "Copy Code"})
    },2000)
  }

  closeEmbedModal = () => {
    this.setState({openEmbedModal:false});
  }

  embedButtonClicked = (feedId:string) => {      
    this.setState({embedFeedId: feedId});
    this.setState({openEmbedModal: true});
  }

  handleTabChange = (selected:number) => {
    this.setState({selectedTabIndex: selected});
  }

  changeTabToInstructions = () => {
    this.setState({selectedTabIndex: 1});
  }

  render() {
    let tabs = [
      {
        id: 'collections-listing',
        content: 'Manage Feeds',
        panelID: 'feeds-panel',
      },
      {
        id: 'suggestions',
        content: 'Suggestions',
        panelID: 'suggestions-panel',
      },
      {
        id: 'support',
        content: 'Support',
        panelID: 'support-panel',
      },
    ];

    let rows: any[] = [];
    let toBeDeletedTitle = this.state.feeds[this.state.deleteIndex] ? this.state.feeds[this.state.deleteIndex].title : '';
    this.state.feeds.forEach((feed, index) => {
      let row = [
        <Link url={"/feeds/"+feed.uuid}>{feed.title}</Link>,
        <Stack>{ this.renderUsernameBadges(index) }</Stack>,
        <Stack>{ this.renderHashtagBadges(index) }</Stack>,
        <Stack>{ this.renderLocationBadges(index) }</Stack>,
        <Stack>
          <Button destructive size="slim" onClick={() => this.openDeleteModal(index)}>Delete</Button>
          <Button size="slim" onClick={()=>{this.embedButtonClicked(feed.uuid)}} >Embed</Button>
        </Stack>
      ]
      rows.push(row);
    });

    return (
      <React.Fragment>
        <Modal open={this.state.openEmbedModal} onClose={this.closeEmbedModal} title="Copy Embed Code"
        primaryAction={{
          content: 'Close',
          onAction: this.closeEmbedModal,
        }}>
          <Modal.Section>
            <TextStyle>Copy the embed code below and paste it in a theme section or store page<br/><br/></TextStyle>
            <TextStyle variation="code">{'<div class="vw-instagram-slider-feed" data-id="' + this.state.embedFeedId + '"></div>'}</TextStyle>
          </Modal.Section>
        </Modal>

        {this.state.hasLoaded && !this.state.errorLoading && this.state.feeds.length>0 && (
          <Modal
              open={this.state.shouldOpenDeleteModal}
              onClose={this.closeDeleteModal}
              title="Delete feed"
              primaryAction={{
                content: 'Delete and Uninstall',
                onAction: this.confirmDelete,
                destructive: true,
                disabled: this.state.deleteLoading
              }}
              secondaryActions={[
                {
                  content: 'Cancel',
                  onAction: this.closeDeleteModal,
                  disabled: this.state.deleteLoading
                },
              ]}
            >
              <Modal.Section>Are you sure you want to delete '" + toBeDeletedTitle + "'? This is irreversible and the feed will no longer work on your theme. If you have added section/s of embed code from this feed, please remove them from your theme before deleting.</Modal.Section>
          </Modal>
        )}

        {!this.state.hasLoaded && (<Loading />)}
        {!this.state.hasLoaded && (<div><Layout><Spinner size="large" /></Layout></div>)}
        {this.state.hasLoaded && this.state.errorLoading &&
        (
          <Banner
            title="Error loading account"
            status="critical"
          >
            <p>Please ensure that you are logged in on Shopify.</p>
          </Banner>
        )}
        {this.state.hasLoaded && !this.state.errorLoading && this.state.feeds.length==0 && 
          (
            <EmptyState
              heading="InstaSlides"
              action={{content: 'Create a feed', url: '/new'}}
              image={emptyStateImage}>
                <p>Create an Instagram slider feed combining one or more usernames, hashtags and locations. Install on your shop without coding.</p>
            </EmptyState>
          )}
        {this.state.hasLoaded && this.state.feeds.length>0 && (
          <Card>
          <Tabs tabs={tabs} selected={this.state.selectedTabIndex} onSelect={this.handleTabChange}></Tabs>
          {this.state.selectedTabIndex === 0 && (
            <React.Fragment>
              <Card.Section>
                <Stack>
                  <Stack.Item fill>
                    <Stack>
                      <DisplayText size="large">Manage Feeds</DisplayText>
                      {this.state.isRefreshing && (<div className="main-spinner"><Spinner size="small" /></div>)}
                    </Stack>
                  </Stack.Item>
                  <Stack.Item>
                    <Button url='/new' primary>Create Slider Feed</Button>
                  </Stack.Item>
                </Stack>
                  <div>
                    <p>To install the feed/s in your theme:<br /><br /></p>
                    <List type="number">
                      <List.Item>
                        <p>First, <Link external url={this.state.themeSettingsURL}>go to Theme Settings by clicking here.</Link> In the 'App Embeds' tab on the right sidebar of the theme settings, you can toggle the switch beside 'Instagram Slider Feed' on.</p>
                      </List.Item>
                      <List.Item>Click on the 'Embed' button beside the feed you are looking to install in your theme, and copy the code.</List.Item>
                      <List.Item>If your theme supports Shopify 2.0, you can use the Theme Customize interface. Go to the template you are looking to install the feed e.g. Home Page. Click on 'Add Section' on the left sidebar and choose 'Custom Liquid', and then paste the embed code in and save. If your theme does not support Shopify 2.0, you can copy and paste the embed code in the Edit Code interface instead.</List.Item>
                      <List.Item>You can also paste the embed code into any page or product in HTML mode by clicking on the '&lt;&gt;' button in the content editor.</List.Item>
                    </List>
                  </div>
              </Card.Section>
              <Card.Section>
                <DataTable
                  columnContentTypes={[
                    'text',
                    'text',
                    'text',
                    'text',
                    'text',
                  ]}
                  headings={[
                    'Title',
                    'Usernames',
                    'Hashtags',
                    'Locations',
                    'Actions'
                  ]}
                  rows={rows} />
                </Card.Section>
              </React.Fragment>)}

              {(this.state.selectedTabIndex === 1) && (
                // Suggestions
                <React.Fragment>
                  <Card.Section>
                    <DisplayText size="large">Suggestions</DisplayText>
                    <p>There are many ways to use InstaSlides to increase engagement and improve customer experience. Here are a few suggestions -<br /><br /></p>
                    <List type="number">
                      <TextStyle variation="strong"><List.Item>Curate feeds related to your product offerings</List.Item></TextStyle>
                      <p>Your Instagram slider feed doesn't just have to be your own posts. Consider finding hashtags which relates to your product offerings. For example, if you are selling plants online, you can use #houseplantclub, #plantsonpink and #plantshelfie to inspire your customers to turn their homes into a plant santuary! Perhaps this can be installed on one of your related blog posts.<br/><br/></p>
                      <TextStyle variation="strong"><List.Item>Showcasing your Instagram account on your home page</List.Item></TextStyle>
                      <p>Consider showing your posts on your home page to showcase how your customers can use your products, and potentially gain more followers. If you have multiple affiliated Instagram accounts, for example if you are a local retailer of an international brand, you can include some or all of the usernames on one single slider feed.<br/><br/></p>
                      <TextStyle variation="strong"><List.Item>Drive traffic to your physical stores under your stores listing</List.Item></TextStyle>
                      <p>If you have a physical presence, consider showcasing the happenings around and in your stores by using locations when you create your slider feed. Perhaps you can install this on your store listing page to encourage your customers to visit you!<br/><br/></p>
                    </List>
                  </Card.Section>
                </React.Fragment>
              )}

                {(this.state.selectedTabIndex === 2) && (
                // Support
                <React.Fragment>
                  <Card.Section>
                    <DisplayText size="large">Support</DisplayText>
                    <p>If you have any questions about InstaSlides, we are here to help!</p>
                  </Card.Section>
                  <Card.Section title="Frequently Asked Questions">
                  <List type="number">
                    <TextStyle variation="strong"><List.Item>Why does InstaSlides use top posts from Instagram rather than recent posts?</List.Item></TextStyle>
                    <p>We have found that recent posts can be filled with spam for hashtags and locations, and therefore we chose to use top posts.<br/><br/></p>
                    <TextStyle variation="strong"><List.Item>Why is my feed loading slower than expected?</List.Item></TextStyle>
                    <p>It might be because of the number of usernames, hashtags and/or locations you have chosen for the feed. While we do not put any limit on this, it is recommended that you use 5 or less in total.<br/><br/></p>
                    <TextStyle variation="strong"><List.Item>My theme does not support Shopify 2.0 and I would like to add a feed, but I am not sure how to add the embed code.</List.Item></TextStyle>
                    <p>You can add the code via Edit Code in the themes section. If you are unsure how to do it, please contact us and we can help!<br/><br/></p>
                  </List>
                  </Card.Section>
                  <Card.Section title="Contact Us">
                    <p>If you require further assistance or if you have any suggestions for improving the app, please contact us at <Link external url="mailto:hello@voidworks.co?subject=Support on InstaSlides">hello@voidworks.co</Link>!</p>
                  </Card.Section>
                </React.Fragment>
              )}

          </Card>)}
      </React.Fragment>
    );
  }
}

export default FeedListing;
