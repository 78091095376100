import $ from "jquery";
import slick from "slick-carousel";

function initFeed(locations, usernames, tags, element, theme, id) {
    $(() => {

        if (theme.isPreview && (locations.length > 0 || usernames.length > 0 || tags.length > 0)) {
            let div = $('<div>', {
                class: 'ig-loader loader-preview'
            });
            div.append(`
                <svg style="margin: auto; display: block; width: 80px;" width="160px"  height="160px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" preserveAspectRatio="xMidYMid" class="lds-rolling" style="background: none;"><circle cx="40" cy="40" fill="none" ng-attr-stroke="{{config.color}}" ng-attr-stroke-width="{{config.width}}" ng-attr-r="{{config.radius}}" ng-attr-stroke-dasharray="{{config.dasharray}}" stroke="#b4b4b4" stroke-width="4" r="25" stroke-dasharray="117.80972450961724 41.269908169872416" transform="rotate(212.198 40 40)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 40 40;360 40 40" keyTimes="0;1" dur="1.3s" begin="0s" repeatCount="indefinite"></animateTransform></circle></svg>
                `
            );
            $(element).append(div);
        }

        let numOfEntitiesLoaded = 0;
        let allImageData = [];
        
        for (let location of locations) {
            loadFromLocationId(location, theme, element);
        }
        for (let username of usernames) {
            loadFromUsername(username, theme, element);
        }
        for (let tag of tags) {
            loadFromTag(tag, theme, element);
        }

        function initGallery(gallery, theme) {
            let centerMode = theme.centerMode;
            let desktopResponsiveNumOfSlides = theme.desktopResponsiveNumOfSlides;
            let tabletResponsiveNumOfSlides = theme.tabletResponsiveNumOfSlides;
            let mobileResponsiveNumOfSlides = theme.mobileResponsiveNumOfSlides;
            let autoplay = theme.autoplay;
            let autoplaySpeed = theme.autoplaySpeed * 1000;

            gallery.slick({
                infinite: true,
                slidesToShow: desktopResponsiveNumOfSlides,
                slidesToScroll: desktopResponsiveNumOfSlides,
                centerMode: centerMode,
                autoplay: autoplay,
                autoplaySpeed: autoplaySpeed,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: tabletResponsiveNumOfSlides,
                            centerPadding: 0,
                            slidesToScroll: tabletResponsiveNumOfSlides,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: mobileResponsiveNumOfSlides,
                            slidesToScroll: mobileResponsiveNumOfSlides,
                            rows: 1,
                            infinite: true
                        }
                    }
                ],
                prevArrow:`
                    <div class='ig-slick-prev'>
                        <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                            <desc>Created with Sketch.</desc>
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                <polyline id="Rectangle" stroke="#FFFFFF" stroke-width="1.6" fill-rule="nonzero" transform="translate(25.000000, 20.000000) scale(-1, 1) rotate(-45.000000) translate(-25.000000, -20.000000) " points="33 12 33 28 17 28"></polyline>
                            </g>
                        </svg>
                    </div>
                `,
                nextArrow:`
                    <div class='ig-slick-next'>
                        <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                            <desc>Created with Sketch.</desc>
                            <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                <polyline id="Rectangle" stroke="#FFFFFF" stroke-width="1.6" fill-rule="nonzero" transform="translate(16.000000, 20.000000) rotate(-45.000000) translate(-16.000000, -20.000000) " points="24 12 24 28 8 28"></polyline>
                            </g>
                        </svg>
                    </div>
                `
            });
        }

        function loadData(edges, theme) {
            combineUniqueArray(allImageData, edges);
            numOfEntitiesLoaded++;
            loadGalleryIfAllLoaded(theme);
        }

        function loadFromLocationId(id, theme) {
            let new_url= "https://sinstafeed.voidworks.co/app/api/ra_requests/?location_id=";
            new_url += id;
            $.ajax({
                type: 'GET',
                url: new_url,
                success: function (dataTwo) {
                    let edges = dataTwo.edges;
                    loadData(edges, theme);
                },
            });
        }

        function loadFromTag(tag, theme) {
            let new_url= "https://sinstafeed.voidworks.co/app/api/ra_requests/?tag=";
            new_url += tag;
            $.ajax({
                type: 'GET',
                url: new_url,
                success: function (dataTwo) {
                    let edges = dataTwo.edges;
                    loadData(edges, theme);
                },
            });
        }

        function loadFromUsername(username, theme) {
            let new_url= "https://sinstafeed.voidworks.co/app/api/ra_requests/?username=";
            new_url += username;
            $.ajax({
                type: 'GET',
                url: new_url,
                success: function (dataTwo) {
                    let edges = dataTwo.edges;
                    loadData(edges, theme);
                },
                error: function () {
                    numOfEntitiesLoaded++;
                },
            });
        }

        function getGalleryDOMFromImageData(imagesData, theme) {
            let gallery = $('<div>', {
                class: 'ig-slider-feed',
                style: 'margin-top:30px'
            });
            let numOfImages = 0;
            for (let image of imagesData) {
                numOfImages++;
                if (numOfImages > 25) {
                    break;
                }
                let container = $('<div>', {
                    class: 'ig-slider-feed-element-container',
                });
                let div = $('<div>', {
                    class: 'ig-slider-feed-element'
                });
                if (theme.imagePadding) {
                    div.attr('style', 'margin:0 5px');
                }
                let node = image.node;
                let likesContainer = $('<div>', {
                    class: 'ig-slider-feed-element-likes-container',
                });
                likesContainer.append(`
                    <svg class="vw-ig-insta-icon" width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                        <title>ico/heart</title>
                        <desc>Created with Sketch.</desc>
                        <g id="ico/heart" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <path d="M20.0571709,8.01920176 C18.187498,6.92776701 16.1118954,6.52035817 14.1797081,6.97497231 C12.2475208,7.42958646 10.4818238,8.75323923 9.22524191,10.9539485 C7.90646283,13.2636008 8.28711966,16.3476807 9.25963622,19.7260171 C10.232123,23.1042532 11.8437692,26.8182382 13.173382,30.4733921 C13.2544614,30.6834607 13.4893934,30.8205665 13.7098837,30.7865908 C17.5039135,30.1236118 21.488579,29.6672938 24.8717107,28.8400484 C28.2548424,28.012803 31.096229,26.8335724 32.4287856,24.4998665 C33.6993433,22.2746024 33.9139063,20.0701848 33.332692,18.156115 C32.7514776,16.2421453 31.3940124,14.6374656 29.5263813,13.5472336 C27.604889,12.4254311 25.2814986,12.7032508 23.3690459,13.2435561 C22.8763846,11.3090407 21.9744011,9.13849866 20.0571907,8.01920176 L20.0571709,8.01920176 Z" id="Path" stroke="#FFFFFF" stroke-width="1.6" fill-rule="nonzero" transform="translate(21.007833, 18.791752) rotate(-31.000000) translate(-21.007833, -18.791752) "></path>
                        </g>
                    </svg>
                `)
                let likes = $('<p>', {
                    class: 'ig-slider-feed-element-likes',
                    text: abbreviateNumber(node.edge_media_preview_like.count,1)
                });
                let commentsContainer = $('<div>', {
                    class: 'ig-slider-feed-element-comments-container',
                });
                commentsContainer.append(`
                    <svg class="vw-ig-insta-icon" width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                        <title>ico/comment</title>
                        <desc>Created with Sketch.</desc>
                        <g id="ico/comment" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <path d="M30.586219,10 L10.413781,10 C8.5284105,9.99999838 7.0000116,11.4955434 7,13.3403996 L7,23.6856724 C7.00000663,25.5305316 8.52840747,27.0260811 10.413781,27.0260778 L22.924792,27.0260778 L29.0494583,33.6727597 C29.4237078,34.0789072 30.0563445,34.1047655 30.462492,33.730516 C30.7020966,33.5097294 30.8191166,33.1860565 30.776099,32.8630912 L29.998633,27.0260778 L30.586219,27.0260778 C32.4715925,27.0260811 33.9999934,25.5305316 34,23.6856724 L34,13.3403996 C33.9999884,11.4955434 32.4715895,9.99999838 30.586219,10 Z" id="Path" stroke="#FFFFFF" stroke-width="1.6" fill-rule="nonzero"></path>
                        </g>
                    </svg>
                `);
                let comments = $('<p>', {
                    class: 'ig-slider-feed-element-comments',
                    text: abbreviateNumber(node.edge_media_to_comment.count,1)
                });
                let meta = $('<div>', {
                    class: 'ig-slider-feed-element-meta',
                });
                likesContainer.append(likes);
                commentsContainer.append(comments);
                meta.append(likesContainer);
                meta.append(commentsContainer);
                /*let timeText = timeSince(new Date(node.taken_at_timestamp*1000));
                let time = $('<p>', {
                    text: timeText
                });
                time.appendTo(div);*/
                let thumb = 'https://imageproxy-2o6sj.ondigitalocean.app/600/' + node.thumbnail_src;
                let img = $('<div>', {
                    class: 'ig-slider-feed-element-image',
                    style: 'background-image: url(' + thumb + '); background-size: contain;'
                });

                let a = $('<a>', {
                    href: "https://www.instagram.com/p/" + node.shortcode,
                    target: '_blank'
                })
                a.append(img);
                div.append(a);
                let metaA = $('<a>', {
                    href: "https://www.instagram.com/p/" + node.shortcode,
                    target: '_blank'
                })
                metaA.append(meta);
                div.append(metaA);
                /*let captions = node.edge_media_to_caption.edges;
                for (let caption of captions) {
                    let p = $('<p>', {
                        text: caption.node.text,
                    });
                    p.appendTo(div);
                }
                */
                container.append(div);
                gallery.append(container);
            }
            return gallery;
        }

        function getEntryData(data) {
            return JSON.parse(data.split("window._sharedData = ")[1].split(";</script>")[0]).entry_data;
        }

        function timeSince(timeStamp) {
            var now = new Date(),
            secondsPast = (now.getTime() - timeStamp.getTime()) / 1000;
            if (secondsPast < 60) {
            return parseInt(secondsPast) + ' seconds ago';
            }
            if (secondsPast < 3600) {
            return parseInt(secondsPast/60) + ' minutes ago';
            }
            if (secondsPast <= 86400) {
            return parseInt(secondsPast/3600) + ' hours ago';
            }
            if (secondsPast > 86400) {
                let day = timeStamp.getDate();
                let month = timeStamp.toDateString().match(/ [a-zA-Z]*/)[0].replace(" ","");
                let year = timeStamp.getFullYear() === now.getFullYear() ? "" :  " "+timeStamp.getFullYear();
                return day + " " + month + year;
            }
        }

        function combineUniqueArray(arr1, arr2) {
            for (var i = 0, l = arr2.length; i < l; i++) {
                let shouldAdd = true;
                for (var j = 0, ll = arr1.length; j < ll; j++) {
                    if (arr2[i].node.id === arr1[j].node.id) {
                        shouldAdd = false;
                        break;
                    }
                }
                if (shouldAdd) {
                    arr1.push(arr2[i]);
                }
            }
        }

        function loadGalleryIfAllLoaded(theme) {
            if (numOfEntitiesLoaded === locations.length + tags.length + usernames.length) {
                allImageData.sort(function(a,b) {
                    return b.node.taken_at_timestamp - a.node.taken_at_timestamp
                });
                let galleryEl = getGalleryDOMFromImageData(allImageData, theme);
                initGallery(galleryEl, theme);
                let div = $('<div>',{ class: ''});
                if (!theme.hideTitle && theme.showTitle) { //hide title is for preview purposes only
                    let header = $('<header>', { class: 'section-header text-center', style: 'margin-top:50px' })
                    let title = $('<h2>', {
                        text: theme.title,
                    });
                    header.append(title);
                    div.append(header);
                } 
                div.append(galleryEl);
                if (id) {
                    $('.loader-'+id).remove();
                }
                $('.loader-preview').remove();
                $(element).append(div);
            }
        }

        function abbreviateNumber(num, fixed) {
            if (num === null) { return null; } // terminate early
            if (num === 0) { return '0'; } // terminate early
            fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
            var b = (num).toPrecision(2).split("e"), // get power
                k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
                c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3) ).toFixed(1 + fixed), // divide by power
                d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
                e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
            return e;
          }

    });
}

function destroyFeed(element) {
    $(element).empty();
}


function resetFeed(locations, usernames, tags, element, theme) {
    $(element).empty();
    initFeed(locations, usernames, tags, element, theme);
}

export { initFeed, destroyFeed, resetFeed };