import React, { Component } from 'react';
import './App.css';
import '@shopify/polaris/dist/styles.css'

import { BrowserRouter as Router, Route } from "react-router-dom";
import FeedListingPage from "./pages/FeedListingPage";
import NewFeedPage from "./pages/NewFeedPage";
import FeedPage from "./pages/FeedPage";
import InstallPage from "./pages/InstallPage";
import LoginPage from "./pages/LoginPage";

interface AppProps {

}

interface AppState {

}

class App extends Component <AppProps, AppState> {

  constructor(props: AppProps) {
    super(props);
  }

  componentDidMount() {
  }
  
  render() {
    return (
      <Router>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/feed-listing" component={FeedListingPage} />
        <Route exact path="/new" component={NewFeedPage} />
        <Route path="/feeds/:feedId" component={FeedPage} />
        <Route path="/install/:feedId" component={InstallPage} />
      </Router>
    );
  }
}

export default App;
