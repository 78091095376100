import React, { Component } from 'react';
import { AppProvider } from '@shopify/polaris';
import { Provider } from '@shopify/app-bridge-react';
import createApp from "@shopify/app-bridge";
import { Redirect } from '@shopify/app-bridge/actions';
import { getFetch } from '../helpers/request';


interface LoginPageProps { }

interface LoginPageState { 
  shopOrigin: string,
  apiKey: string
}

class LoginPage extends Component <LoginPageProps, LoginPageState> {

  constructor(props: LoginPageProps) {
    super(props);
    this.state = { 
      shopOrigin: '',
      apiKey: ''
    };
  }

  componentDidMount() {
    let shopHost = new URLSearchParams(window.location.search).get("host");
    getFetch('/app/api/shopify/auth', {})
      .then((res: any) => res.json())
      .then((data: any) => {
          const app:any = createApp({
            host:shopHost!,
            apiKey: (window as any).apiKey,
          });
          const redirect = Redirect.create(app);
          if (data.uninstalled) {
            // redirect to app
            redirect.dispatch(Redirect.Action.APP, '/app');
          } else {
            // redirect to main page
            redirect.dispatch(Redirect.Action.APP, '/feed-listing');
          }
        }).catch((err: any) => {
            // redirect to app
            console.log('redirecting to app login');
            window.location.href = this.getRedirectURL();
        });
  }
  
  render() {
    const config = {apiKey: this.state.apiKey, shopOrigin: this.state.shopOrigin, host:Buffer.from('admin.shopify.com/store/' + this.state.shopOrigin.split('.')[0]).toString('base64')};
    return (
        this.state.shopOrigin !== '' && this.state.apiKey !== '' && 
        <AppProvider i18n={{}}>
          <Provider config={config}>
            
          </Provider>
        </AppProvider>
    );
  }

  getRedirectURL() {
    const queryString = window.location.href.split('?')[1];
    return '/app?' + queryString;
  }
}

export default LoginPage;