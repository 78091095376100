import React, { Component } from 'react';
import './NewFeedPage.css';
import '@shopify/polaris/dist/styles.css'
import {AppProvider, Frame} from '@shopify/polaris';
import {Provider} from '@shopify/app-bridge-react';
import Feed from '../components/Feed';
import { getFetch } from '../helpers/request';

interface NewFeedPageProps {

}

interface NewFeedPageState {
  shopOrigin: string,
  apiKey: string
}

class NewFeedPage extends Component <NewFeedPageProps, NewFeedPageState> {

  constructor(props: NewFeedPageProps) {
    super(props);
    this.state = { 
      shopOrigin: '',
      apiKey: ''
    };
  }

  componentDidMount() {
    //check if log in
    getFetch('/app/api/shopify/user', {})
    .then((res:any) => res.json()
    ).then(data => { 
      this.setState({ shopOrigin: data.shop_origin });
      this.setState({ apiKey: data.api_key });
    }).catch(err => {

    });
  }
  
  render() {
    const config = {apiKey: this.state.apiKey, shopOrigin: this.state.shopOrigin, host:Buffer.from('admin.shopify.com/store/' + this.state.shopOrigin.split('.')[0]).toString('base64')};
    return (
      this.state.shopOrigin !== '' && this.state.apiKey !== '' &&
      <AppProvider i18n={{}}>
        <Provider config={config}>
          <Frame>
            <Feed type="new"></Feed>
          </Frame>
        </Provider>
      </AppProvider>
    );
  }
}

export default NewFeedPage;
