import React, { Component } from 'react';
import './InstallPage.css';
import Install from '../components/Install';
import '@shopify/polaris/dist/styles.css';
import {Provider} from '@shopify/app-bridge-react';
import {AppProvider, Frame, Page} from '@shopify/polaris';
import { getFetch } from '../helpers/request';

interface InstallPageProps {
  id: string,
  match: any
}

interface InstallPageState {
  shopOrigin: string,
  apiKey: string
}

class InstallPage extends Component <InstallPageProps, InstallPageState> {

  constructor(props: InstallPageProps) {
    super(props);
    this.state = { 
      shopOrigin: '',
      apiKey: ''
    };
  }

  componentDidMount() {
    //check if log in
    getFetch('/app/api/shopify/user', {})
    .then((res:any) => res.json()
    ).then(data => { 
      this.setState({ shopOrigin: data.shop_origin });
      this.setState({ apiKey: data.api_key });
    }).catch(err => {

    });
  }
  
  render() {
    const config = {apiKey: this.state.apiKey, shopOrigin: this.state.shopOrigin, host: Buffer.from('admin.shopify.com/store/' + this.state.shopOrigin.split('.')[0]).toString('base64')};
    return (
      this.state.shopOrigin !== '' && this.state.apiKey !== '' &&
      <AppProvider i18n={{}}>
        <Provider config={config}>
          <Frame>
            <Page title="Install Feed">
              <Install apiKey={this.state.apiKey} shopOrigin={this.state.shopOrigin} id={this.props.match.params.feedId}></Install>
            </Page>
          </Frame>
        </Provider>
      </AppProvider>
    );
  }
}

export default InstallPage;
