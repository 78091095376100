import React, { Component } from 'react';
import { getFetch, deleteFetch, postFetch } from '../helpers/request';
import './Install.css';
import '@shopify/polaris/dist/styles.css'
import { Card, DataTable, Button, Select, Banner, Stack, Link,
  Loading, Spinner, Modal, Layout,TextStyle, FormLayout } from '@shopify/polaris';
import { Redirect } from 'react-router';
import PropTypes from "prop-types";

interface InstallProps {
  id: string,
  shopOrigin: string,
  apiKey: string
}

interface InstallState {
  installations: Array<any>,
  hasLoaded: boolean,
  errorLoading: boolean,
  isRefreshing: boolean,
  showBanner: boolean,
  shouldOpenDeleteModal: boolean,
  deleteIndex: number,
  feedTitle: string,
  selectedPage: string,
  selectedPosition: string,
  pages: Array<any>,
  pageOptions: Array<any>,
  positionOptions: Array<any>,
  loadingInstall: boolean,
  loadingDelete: boolean,
  copyCodeText: string,
  redirectToListing: boolean,
}

class Install extends Component <InstallProps, InstallState> {
  
  static contextTypes = { polaris: PropTypes.object, };

  constructor(props: InstallProps) {
    super(props);
    this.state = { 
      installations: [],
      hasLoaded: false,
      errorLoading: false,
      isRefreshing: false,
      showBanner: true,
      shouldOpenDeleteModal: false,
      deleteIndex: 0,
      feedTitle: '',
      selectedPage: '',
      selectedPosition: 'bottom',
      pages: [],
      pageOptions: [],
      loadingInstall: false,
      copyCodeText: "Copy Code",
      positionOptions: [
        {label: 'Top of Page', value: 'top'},
        {label: 'Bottom of Page', value: 'bottom'},
      ],
      loadingDelete: false,
      redirectToListing: false,
    };
  }

  componentDidMount() {
    getFetch('/app/api/installations/?id='+this.props.id, {})
    .then((res:any) => res.json()
    ).then(data => { 
      console.log(data);
      let pages = data.pages;
      let pageOptions = [];
      for (let page of pages) {
        let o = { 
          value: page.id.toString(),
          label: page.title
        };
        pageOptions.push(o);
      }
      this.setState({ pages: pages });
      if (pages.length > 0) {
        this.setState({selectedPage: pages[0].id.toString()});
      }
      console.log(pageOptions);
      this.setState({ pageOptions: pageOptions });
      this.setState({ feedTitle: data.title });
      this.setState({ installations: data.installations });
      this.setState({ hasLoaded: true });
    }).catch(err => {
      this.setState({ hasLoaded: true });
      this.setState({ errorLoading: true });
    });
  }

  componentWillUnmount() {

  }

  refresh = () => {
    getFetch('/app/api/installations/?id='+this.props.id, {})
    .then((res:any) => res.json()
    ).then(data => { 
      this.setState({loadingInstall: false});
      this.setState({ installations: data.installations });
    }).catch(err => {
      this.setState({loadingInstall: false});
    });
  }

  openDeleteModal = (index:number) => {
    this.setState({deleteIndex: index});
    this.setState({shouldOpenDeleteModal:true});
  }

  closeDeleteModal = () => {
    this.setState({shouldOpenDeleteModal:false});
  }

  confirmDelete = () => {
    let deleteIndex = this.state.deleteIndex;
    let id = this.state.installations[deleteIndex].id;
    this.setState({loadingDelete: true})
    let newInstallations = this.state.installations.filter((installation)=> {
      return installation.id !== id
    });
    deleteFetch('/app/api/installations/', { id: id })
    .then((res:any) => res.json()
    ).then(data => {
      this.closeDeleteModal();
      this.setState({installations: newInstallations});
      this.setState({loadingDelete: false})
    }).catch(err => {
      this.setState({loadingDelete: false})
    });
    
  }

  handlePageSelection = (newValue:string) => {
    console.log(newValue);
    this.setState({selectedPage: newValue});
  };

  handlePositionSelection = (newValue:string) => {
    this.setState({selectedPosition: newValue});
  };

  clickInstall = () => {
    this.setState({loadingInstall: true});
    let body = {
      id: this.props.id,
      installation: {
        id: this.state.selectedPage,
        position: this.state.selectedPosition,
        type: this.state.pages.filter((page) => {
          return page.id.toString() === this.state.selectedPage
        })[0].type
      }
    }
    postFetch('/app/api/installations/', body)
    .then((res:any) => res.json()
    ).then(data => {
      this.refresh();
    }).catch(err => {
      this.setState({loadingInstall: false});
    });
    
  }

  copyCodeToClipboard = () => {
    var textField = document.createElement('textarea');
    let dataId = this.props.id.replace(/-/g,'');
    textField.innerText = '<div class="vw-instagram-slider-feed" data-id="' + dataId + '"></div>';
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    this.setState({copyCodeText: "Copied!"})
    setTimeout(() => {
      this.setState({copyCodeText: "Copy Code"})
    },2000)
  }

  render() {
    if (this.state.redirectToListing === true) {
      return <Redirect to='/feed-listing' />
    } 

    let rows: any[] = [];
    let dataId = this.props.id.replace(/-/g,'');
    let toBeDeletedTitle = this.state.installations[this.state.deleteIndex] ? this.state.installations[this.state.deleteIndex].title : '';
    let toBeDeletedPosition = '';
    if (this.state.installations[this.state.deleteIndex]) {
      let installation = this.state.installations[this.state.deleteIndex];
      toBeDeletedPosition = this.state.positionOptions.filter((option) => { return option.value === installation.position})[0].label
    }
    this.state.installations.forEach((installation, index) => {
      let row = [
        <TextStyle>{installation.title}</TextStyle>,
        <TextStyle>{this.state.positionOptions.filter((option) => { return option.value === installation.position})[0].label}</TextStyle>,
        <Stack>
          <Button destructive size="slim" onClick={() => this.openDeleteModal(index)}>Uninstall</Button>
          {installation.url && (<Link external url={installation.url}>View Page</Link>)}
        </Stack>
      ]
      rows.push(row);
    });

    return (
      <div>
        {this.state.hasLoaded && !this.state.errorLoading && this.state.installations.length>0 && (
          <Modal
              open={this.state.shouldOpenDeleteModal}
              onClose={this.closeDeleteModal}
              title="Uninstall"
              primaryAction={{
                content: 'Uninstall',
                onAction: this.confirmDelete,
                destructive: true,
                disabled: this.state.loadingDelete
              }}
              secondaryActions={[
                {
                  content: 'Cancel',
                  onAction: this.closeDeleteModal,
                  disabled: this.state.loadingDelete
                },
              ]}
            >
              <Modal.Section>{"Are you sure you want to uninstall your feed for '" + toBeDeletedTitle + "' at '" + toBeDeletedPosition + "'? You can always come back to reinstall it."}</Modal.Section>
          </Modal>
        )}

        {!this.state.hasLoaded && (<Loading />)}
        
        {this.state.hasLoaded && this.state.errorLoading &&
        (
          <Banner
            title="Error loading account"
            status="critical"
          >
            <p>Please ensure that you are logged in on Shopify.</p>
          </Banner>
        )}
        {this.state.hasLoaded && !this.state.errorLoading && this.state.installations.length === 0 && 
          (
            <TextStyle></TextStyle>
          )}
        {!this.state.hasLoaded && (<div><Layout><Spinner /></Layout></div>)}
        {this.state.hasLoaded && (
          <Card sectioned secondaryFooterActions={[{ content: 'Done', onAction: ()=>{
              this.setState({redirectToListing:true})
            }}]}>
            <Card.Header title={"Install '" + this.state.feedTitle + "'"}></Card.Header>
            <Card.Section title="Automatic Installation">
            <FormLayout>
              <Stack alignment="trailing">
                <Select
                  label="Choose page"
                  options={this.state.pageOptions}
                  onChange={this.handlePageSelection}
                  value={this.state.selectedPage}
                />
                <Select
                  label="Choose position"
                  options={this.state.positionOptions}
                  onChange={this.handlePositionSelection}
                  value={this.state.selectedPosition}
                />
                <Button primary onClick={this.clickInstall} loading={this.state.loadingInstall}>Install</Button>
              </Stack>
            </FormLayout>
            </Card.Section>
            {this.state.installations.length>0 && (
              <Card.Section title="Current Automatic Installations">
                  <DataTable
                    columnContentTypes={[
                      'text',
                      'text',
                      'text',
                    ]}
                    headings={[
                      'Page',
                      'Position',
                      'Actions',
                    ]}
                    rows={rows} />
                
              </Card.Section>
            )}
            <Card.Section title="Custom Installation">
              <TextStyle>Can't find the page or location you want to install the feed? You can do a custom installation by copying the following code and pasting it on the relevant HTML editor e.g. in individual articles. For more detailed instructions, <Link url="/tabs/1">click here</Link><br/><br/></TextStyle>
              <TextStyle variation="code">{'<div class="vw-instagram-slider-feed" data-id="' + dataId + '"></div>'}</TextStyle>
              <div className="mt">
                <Button onClick={this.copyCodeToClipboard}>{this.state.copyCodeText}</Button>
              </div>
            </Card.Section>
          </Card>
        )}
      </div>
    );
  }
}

export default Install;
