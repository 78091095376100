import React, { Component } from 'react';
import FeedPreview from './FeedPreview';
import './Feed.css';
import '@shopify/polaris/dist/styles.css'
import { Loading, Page, Card, Select, TextField, FormLayout, DisplayText, Layout, Spinner, Checkbox, Stack } from '@shopify/polaris';
import { getFetch, postFetch, putFetch } from '../helpers/request';
import { Redirect } from 'react-router';
import AutocompleteTags from './AutocompleteTags'

interface FeedProps {
  type: string,
  id?: string
}

interface FeedState {
  themeSupportsAppExtensions: boolean,
  hasLoaded: boolean,
  errorLoading: boolean,
  title: string,
  isSaving: boolean,
  titleTouched: boolean,
  redirectToListing: boolean,
  redirectToInstall: boolean,
  redirectId: string,
  showBanner:boolean,
  usernames: Array<any>,
  hashtags: Array<any>,
  locations: Array<any>,
  theme: any,
  desktopResponsiveNumOfSlidesOptions: Array<any>,
  tabletResponsiveNumOfSlidesOptions: Array<any>,
  mobileResponsiveNumOfSlidesOptions: Array<any>,
  responsiveOptionsLoading: boolean,
  autoplay: boolean,
  autoplaySpeed: string
}

class Feed extends Component <FeedProps, FeedState> {

  el:any;

  constructor(props: FeedProps) {
    super(props);
    this.state = { 
      themeSupportsAppExtensions: false,
      responsiveOptionsLoading: false,
      isSaving: false,
      hasLoaded: false,
      errorLoading: false,
      redirectToListing: false,
      redirectToInstall: false,
      redirectId: '',
      showBanner: true,
      title: '',
      titleTouched: false,
      usernames: [],
      hashtags: [],
      locations: [],
      autoplay:false,
      autoplaySpeed: '5',
      theme: {
        showTitle: true,
        imagePadding: false,
        centerMode: false,
        desktopResponsiveNumOfSlides: 4,
        tabletResponsiveNumOfSlides: 3,
        mobileResponsiveNumOfSlides: 2,
        autoplay: false,
        autoplaySpeed: 5
      },
      desktopResponsiveNumOfSlidesOptions: [
        { label: '5', value: 5, key:'desktop-5' },
        { label: '4', value: 4, key:'desktop-4' },
        { label: '3', value: 3, key:'desktop-3' },
        { label: '2', value: 2, key:'desktop-2' },
      ],
      tabletResponsiveNumOfSlidesOptions: [
        { label: '4', value: 4, key:'tablet-4' },
        { label: '3', value: 3, key:'tablet-3' },
        { label: '2', value: 2, key:'tablet-2' },
        { label: '1', value: 1, key:'tablet-1' }
      ],
      mobileResponsiveNumOfSlidesOptions: [
        { label: '4', value: 4, key:'mobile-4' },
        { label: '3', value: 3, key:'mobile-3' },
        { label: '2', value: 2, key:'mobile-2' },
        { label: '1', value: 1, key:'mobile-1' }
      ]
    };
    this.el = React.createRef();
  }
  

  componentDidMount() {
    if (this.props.type === 'new') {
      getFetch('/app/api/theme-app-extensions', {})
      .then((res:any) => res.json()
        ).then(data => {
          this.setState({ hasLoaded: true });
          this.setState({themeSupportsAppExtensions: data.theme_supports_app_extensions});
        });
    } else if (this.props.type === 'existing') {
      getFetch('/app/api/feeds/?id='+this.props.id, {})
        .then((res:any) => res.json()
        ).then(data => {
          this.setState({ title: data.title });
          this.setState({ usernames: data.usernames });
          this.setState({ locations: data.locations });
          this.setState({ hashtags: data.hashtags });
          this.setState({ theme: data.theme });

          getFetch('/app/api/theme-app-extensions', {})
          .then((res:any) => res.json()
            ).then(data => {
              this.setState({themeSupportsAppExtensions: data.theme_supports_app_extensions});
              this.setState({ hasLoaded: true });
            });
        });
    }
  }

  save = () => {
    this.setState({isSaving:true});
    
    let body:any = {
      title: this.state.title,
      hashtags: this.state.hashtags,
      locations: this.state.locations,
      usernames: this.state.usernames,
      theme: this.state.theme
    };

    if (this.props.type === 'new') {
      postFetch('/app/api/feeds/', body)
      .then((res:any) => res.json()).then(
        (data) => { 
          this.setState({redirectId: data.id})
          this.setState({redirectToListing: true});
        }
      ).catch( 
        (err) => console.log(err)
      );
    } else if (this.props.type === 'existing') {
      body.id = this.props.id;
      putFetch('/app/api/feeds/', body).then(
        (res) => { 
          this.setState({redirectToListing: true});
        }
      ).catch( 
        (err) => console.log(err)
      );
    }
  }

  handleTitleChange = (value:string) => {
    this.setState({titleTouched: true});
    this.setState({title: value});
  };

  handleShowTitleSettingsChange = (value:boolean) => {
    let theme = this.state.theme;
    theme.showTitle = value;
    this.setState({theme: theme});
  };

  handleAutoplayChange = (value:boolean) => {
    this.setState({responsiveOptionsLoading:true});
    let theme = this.state.theme;
    theme.autoplay = value;
    this.setState({theme: theme});
    this.el.reset(this.state.locations, this.state.usernames, this.state.hashtags, theme);
    setTimeout(()=>this.setState({responsiveOptionsLoading:false}), 2000);
  }

  handleAutoplaySpeedChange = (value:string) => {
    this.setState({responsiveOptionsLoading:true});
    let theme = this.state.theme;
    theme.autoplaySpeed = parseInt(value);
    this.setState({theme: theme});
    this.el.reset(this.state.locations, this.state.usernames, this.state.hashtags, theme);
    setTimeout(()=>this.setState({responsiveOptionsLoading:false}), 2000);
  }

  handleImagePaddingSettingsChange = (value:boolean) => {
    this.setState({responsiveOptionsLoading:true});
    let theme = this.state.theme;
    theme.imagePadding = value;
    this.setState({theme: theme});
    this.el.reset(this.state.locations, this.state.usernames, this.state.hashtags, theme);
    setTimeout(()=>this.setState({responsiveOptionsLoading:false}), 2000);
  };

  handleCenterModeSettingsChange = (value:boolean) => {
    this.setState({responsiveOptionsLoading:true});
    let theme = this.state.theme;
    theme.centerMode = value;
    this.setState({theme: theme});
    this.el.reset(this.state.locations, this.state.usernames, this.state.hashtags, theme);
    setTimeout(()=>this.setState({responsiveOptionsLoading:false}), 2000);
  };

  handleDesktopNumOfSlidesChange = (value:string) => {
    this.setState({responsiveOptionsLoading:true});
    let theme = this.state.theme;
    theme.desktopResponsiveNumOfSlides = parseInt(value);
    this.setState({theme: theme});
    this.el.reset(this.state.locations, this.state.usernames, this.state.hashtags, theme);
    setTimeout(()=>this.setState({responsiveOptionsLoading:false}), 2000);
  }

  handleTabletNumOfSlidesChange = (value:string) => {
    this.setState({responsiveOptionsLoading:true});
    let theme = this.state.theme;
    theme.tabletResponsiveNumOfSlides = parseInt(value);
    this.setState({theme: theme});
    this.el.reset(this.state.locations, this.state.usernames, this.state.hashtags, theme);
    setTimeout(()=>this.setState({responsiveOptionsLoading:false}), 2000);
  }

  handleMobileNumOfSlidesChange = (value:string) => {
    this.setState({responsiveOptionsLoading:true});
    let theme = this.state.theme;
    theme.mobileResponsiveNumOfSlides = parseInt(value);
    this.setState({theme: theme});
    this.el.reset(this.state.locations, this.state.usernames, this.state.hashtags, theme);
    setTimeout(()=>this.setState({responsiveOptionsLoading:false}), 2000);
  }

  selectedUsernamesChange = (usernames: Array<string>) => {
    this.setState({responsiveOptionsLoading:true});
    this.setState({usernames: usernames});
    this.el.reset(this.state.locations, usernames, this.state.hashtags, this.state.theme);
    setTimeout(()=>this.setState({responsiveOptionsLoading:false}), 2000);
  }

  selectedHashtagsChange = (hashtags: Array<string>) => {
    this.setState({responsiveOptionsLoading:true});
    this.setState({hashtags: hashtags});
    this.el.reset(this.state.locations, this.state.usernames, hashtags, this.state.theme);
    setTimeout(()=>this.setState({responsiveOptionsLoading:false}), 2000);
  }

  selectedLocationsChange = (locations: Array<string>) => {
    this.setState({responsiveOptionsLoading:true});
    this.setState({locations: locations});
    this.el.reset(locations, this.state.usernames, this.state.hashtags, this.state.theme);
    setTimeout(()=>this.setState({responsiveOptionsLoading:false}), 2000);
  }
  
  initTags(data:any) {
    let availableTags = data.tags;
    availableTags.sort();
    this.setState({ hasLoaded: true });
  }
  

  render() {
    if (this.state.redirectToListing === true) {
      return <Redirect to='/feed-listing' />
    } else if (this.state.redirectToInstall === true) {
      let url = '/install/' + this.state.redirectId;
      return (<Redirect to={url} />)
    }
    let autoplayOptions:any = [
      { label: '10 secs', value: 10, key:'10 secs' },
      { label: '9 secs', value: 9, key:'9 secs' },
      { label: '8 secs', value: 8, key:'8 secs' },
      { label: '7 secs', value: 7, key:'7 secs' },
      { label: '6 secs', value: 6, key:'6 secs' },
      { label: '5 secs', value: 5, key:'5 secs' },
      { label: '4 secs', value: 4, key:'4 secs' },
      { label: '3 secs', value: 3, key:'3 secs' },
      { label: '2 secs', value: 2, key:'2 secs' },
      { label: '1 sec', value: 1, key:'1 sec' },
    ]
    const FeedTitleRegex = /^[a-zA-Z0-9-_.& ]+$/;
    return (
        <Page 
          title={this.props.type === 'new' ? "Create New Feed" : "Edit Feed"}
          breadcrumbs={[{content: 'Manage Feeds', onAction: ()=>{this.setState({redirectToListing:true})}}]}>
          {!this.state.hasLoaded && (<Loading />)}
          {!this.state.hasLoaded && (<div><Layout><Spinner size="large" /></Layout></div>)}
          {this.state.hasLoaded && (
            <Card sectioned secondaryFooterActions={[{content: 'Cancel', onAction: ()=>{this.setState({redirectToListing:true})}}]}
              primaryFooterAction={{content: 'Save', 
              loading:this.state.isSaving, 
              disabled:this.state.title.length === 0 || this.state.hashtags.length + this.state.usernames.length + this.state.locations.length === 0,
              onAction:this.save}}>
              <Card.Section>
                <p><span className="Polaris-Subheading" style={{marginRight:"4px"}}>Settings</span>
                    {this.state.responsiveOptionsLoading && (<Spinner size="small" />)}
                </p>
                <FormLayout>
                  <TextField
                    label="Enter title"
                    value={this.state.title}
                    onChange={this.handleTitleChange}
                    error={this.state.titleTouched && this.state.title.length === 0 ? "Title is required" : 
                          //!FeedTitleRegex.test(this.state.FeedTitle) ? "Feed name contains illegal characters" :
                          false}
                  />
                  <Stack>
                    <Checkbox
                      checked={this.state.theme.showTitle}
                      label="Show title"
                      onChange={this.handleShowTitleSettingsChange}
                    />
                    <Checkbox
                      checked={this.state.theme.imagePadding}
                      label="Enable image spacing"
                      onChange={this.handleImagePaddingSettingsChange}
                      disabled={this.state.responsiveOptionsLoading}
                    />
                    <Checkbox
                      checked={this.state.theme.centerMode}
                      label="Center mode"
                      onChange={this.handleCenterModeSettingsChange}
                      disabled={this.state.responsiveOptionsLoading}
                    />
                  </Stack>
                </FormLayout>
              </Card.Section>
              <Card.Section>
                <FormLayout>
                  <p><span className="Polaris-Subheading">Responsive Options</span></p>
                  <Stack>
                    <Select
                      label="Number of slides shown for Desktop"
                      disabled={this.state.responsiveOptionsLoading}
                      options={this.state.desktopResponsiveNumOfSlidesOptions}
                      onChange={this.handleDesktopNumOfSlidesChange}
                      value={this.state.theme.desktopResponsiveNumOfSlides}
                    />
                    <Select
                      label="Number of slides shown for Tablet"
                      disabled={this.state.responsiveOptionsLoading}
                      options={this.state.tabletResponsiveNumOfSlidesOptions}
                      onChange={this.handleTabletNumOfSlidesChange}
                      value={this.state.theme.tabletResponsiveNumOfSlides}
                    />
                    <Select
                      label="Number of slides shown for Mobile"
                      disabled={this.state.responsiveOptionsLoading}
                      options={this.state.mobileResponsiveNumOfSlidesOptions}
                      onChange={this.handleMobileNumOfSlidesChange}
                      value={this.state.theme.mobileResponsiveNumOfSlides}
                    />
                  </Stack>
                </FormLayout>
              </Card.Section>
              <Card.Section title="Usernames, hashtags and locations">
                <FormLayout>
                  <AutocompleteTags disabled={this.state.responsiveOptionsLoading} selected={this.state.usernames} onChange={this.selectedUsernamesChange} label='Add one or more usernames' placeholderText='Enter username' type='usernames'></AutocompleteTags>
                  <AutocompleteTags disabled={this.state.responsiveOptionsLoading} selected={this.state.hashtags} onChange={this.selectedHashtagsChange} label='Add one or more hashtags' placeholderText='Enter hashtag' type='hashtags'></AutocompleteTags>
                  <AutocompleteTags disabled={this.state.responsiveOptionsLoading} selected={this.state.locations} onChange={this.selectedLocationsChange} label='Search the location in Instagram and paste your URL here e.g. https://www.instagram.com/explore/locations/281167589/time-square/' placeholderText='Paste location URL' type='locations'></AutocompleteTags>
                </FormLayout>
              </Card.Section>
              <Card.Section title="Autoplay">
                <FormLayout>
                    <Checkbox
                      checked={this.state.theme.autoplay}
                      label="Enable Autoplay"
                      onChange={this.handleAutoplayChange}
                      disabled={this.state.responsiveOptionsLoading}
                    />
                    {this.state.theme.autoplay && 
                    <Select
                      label="Autoplay Interval"
                      options={autoplayOptions}
                      onChange={this.handleAutoplaySpeedChange}
                      value={this.state.theme.autoplaySpeed}
                      disabled={this.state.responsiveOptionsLoading}
                    />}
                </FormLayout>
              </Card.Section>
              <Card.Section>
                <p><span className="Polaris-Subheading" style={{marginRight:"4px"}}>Preview</span>
                    {this.state.responsiveOptionsLoading && (<Spinner size="small" />)}
                </p>
                <FeedPreview ref={el => this.el = el} hashtags={this.state.hashtags} usernames={this.state.usernames} locations={this.state.locations} title={this.state.title} theme={this.state.theme}></FeedPreview>
              </Card.Section>
            </Card>
          )}
        </Page>
    );
  }
}

export default Feed;
