import React, { Component } from 'react';
import './AutocompleteTags.css';
import '@shopify/polaris/dist/styles.css'
import { TextField, TextContainer, Stack, Tag, TextStyle, Button, Modal } from '@shopify/polaris';

interface AutocompleteTagsProps {
    placeholderText:string,
    label:string,
    onChange: Function,
    selected: Array<any>,
    type: string,
    disabled?: boolean
    
}

interface AutocompleteTagsState {
    selected: Array<any>,
    inputText: string,
    shouldOpenModal: boolean,
    locationError: any
}

class AutocompleteTags extends Component <AutocompleteTagsProps, AutocompleteTagsState> {
    
    constructor(props: AutocompleteTagsProps) {
        super(props);
        this.state = {
            selected: this.props.selected,
            inputText: '',
            shouldOpenModal: false,
            locationError: false
        };
    }

    render() {
      return (
        <div>
          <Stack>
            <TextStyle>{this.props.label}</TextStyle>
          </Stack>
          <TextContainer>
            <Stack>{this.renderTags()}</Stack>
          </TextContainer>
          <br />
          <Stack>
            <div onKeyDown={this.handleKeyPress}>
              <TextField
                label=''
                onChange={this.handleChange}
                value={this.state.inputText}
                placeholder={this.props.placeholderText}
                error={this.state.locationError}
                disabled={this.props.disabled}
              />
            </div>
            <Button disabled={this.props.disabled} onClick={this.handleClick}>Add</Button>
          </Stack>
          <Modal
            open={this.state.shouldOpenModal}
            onClose={this.closeModal}
            title="Getting location id from Instagram"
            src="https://www.google.com"
          >
          </Modal>
        </div>
      );
    }

    onFocus = () => {
        this.setState({

        });
    }

    handleClick = () => {
      let filteredText = this.state.inputText;
      this.addNewTag(filteredText);
    }

    addNewTag = (tag:string) => {
      let newSelected = this.state.selected;
      this.setState({locationError:false});
      if (this.props.type === 'locations') {
        //https://www.instagram.com/explore/locations/281167589/time-square/
        try {
          let data = tag.split('https://www.instagram.com/explore/locations')[1].split('/');
          if (!/^\d+$/.test(data[1]) || data[2].length === 0) {
            this.setState({inputText:''});
            this.setState({locationError:'Please check your URL'})
            return;
          }
          let obj = {
            id: data[1],
            label: data[2] 
          }
          newSelected.push(obj);
        } catch {
          this.setState({inputText:''});
          this.setState({locationError:'Please check your URL'})
          return;
        }
      } else {
        let filtered = tag.replace(/-/g, '').replace(/@/g, '').replace(/#/g, '').replace(/$/g, '').replace(/%/g, '').replace(/ /g, '');
        let filteredTags = filtered.split(',');

        for (let filteredTag of filteredTags) {
          if (newSelected.indexOf(filteredTag) === -1 && filteredTag.length>0) {
            newSelected.push(filteredTag);
          }
        }
      }
      this.setState({inputText:''});
      this.props.onChange(newSelected);
    }

    handleChange = (value: string) => {
      this.setState({locationError:false});
      this.setState({inputText: value});
      const lastChar = value.charAt(value.length - 1);
      if (lastChar === ',' || lastChar === ' ' ) {
        this.addNewTag(value.slice(0, -1));
      }
    }

    handleKeyPress = (event:any) => {
      const enterKeyPressed = event.keyCode === 13;
      if (enterKeyPressed) {
        this.addNewTag(this.state.inputText);
      }
    }
  
    removeTag = (tag:string) => {
      let newSelected = this.state.selected;
      newSelected = newSelected.filter(t => t !== tag);
      this.setState({selected: newSelected});
      this.props.onChange(newSelected);
    };

    openModal = () => {
      this.setState({shouldOpenModal: true});
    }

    closeModal = () => {
      this.setState({shouldOpenModal: false});
    }
  
    renderTags = () => {
      let tagPrefix = '';
      if (this.props.type === 'hashtags') {
        tagPrefix = '#';
      } else if (this.props.type === 'usernames') {
        tagPrefix = '@';
      }
      return this.state.selected.map((tag) => {
        if (this.props.type === 'locations') {
          return (
            <Tag disabled={this.props.disabled} key={'option' + tag.id} onRemove={() => this.removeTag(tag)}>
              {tag.label}
            </Tag>
          );
        } else {
          return (
            <Tag disabled={this.props.disabled} key={'option' + tag} onRemove={() => this.removeTag(tag)}>
              {tagPrefix + tag}
            </Tag>
          );
        }
      });
    };
  }

  export default AutocompleteTags;