import React, { Component } from 'react';
import './FeedListingPage.css';
import '@shopify/polaris/dist/styles.css'
import {AppProvider, Page, Frame} from '@shopify/polaris';
import {Provider} from '@shopify/app-bridge-react';
import FeedListing from '../components/FeedListing';
import { getFetch } from '../helpers/request';

interface FeedListingPageProps {
  match: any
}

interface FeedListingPageState {
  shopOrigin: string,
  apiKey: string
}

class FeedListingPage extends Component <FeedListingPageProps, FeedListingPageState> {

  constructor(props: FeedListingPageProps) {
    super(props);
    this.state = { 
      shopOrigin: '',
      apiKey: ''
    };
  }

  componentDidMount() {
    //check if log in
    getFetch('/app/api/shopify/user', {})
    .then((res:any) => res.json()
    ).then(data => { 
      this.setState({ shopOrigin: data.shop_origin });
      this.setState({ apiKey: data.api_key });
    }).catch(err => {

    });
  }
  
  render() {
    let tabIndex = this.props.match.params.tabIndex === undefined ? 0 : this.props.match.params.tabIndex;
    const config = {apiKey: this.state.apiKey, shopOrigin: this.state.shopOrigin, host:Buffer.from('admin.shopify.com/store/' + this.state.shopOrigin.split('.')[0]).toString('base64')};
    return (
      this.state.shopOrigin !== '' && this.state.apiKey !== '' &&
      <AppProvider i18n={{}}>
        <Provider config={config}>
          <Frame>
            <Page>
              <FeedListing selectedTabIndex={parseInt(tabIndex)}></FeedListing>
            </Page>
          </Frame>
        </Provider>
      </AppProvider>
    );
  }
}

export default FeedListingPage;