import React, { Component } from 'react';
import '../external/slider.css';
import { initFeed, destroyFeed, resetFeed } from '../external/slider.js'
import { TextStyle, DisplayText} from '@shopify/polaris';

interface FeedPreviewProps {
  locations: Array<any>,
  usernames: Array<string>,
  hashtags: Array<string>,
  title: string,
  theme: any
}

interface FeedPreviewState {
  
}
class FeedPreview extends Component <FeedPreviewProps, FeedPreviewState> {
    
    el:any;

    constructor(props: FeedPreviewProps) {
        super(props);
        this.el = React.createRef();
    }

    componentDidMount() {
      this.init();
    }
  
    componentWillUnmount() {
      this.destroy();
    }

    init() {
      let locationIds = [];
      for (let l of this.props.locations) {
        locationIds.push(l.id);
      }
      let previewTheme = {...this.props.theme};
      previewTheme.hideTitle = true;
      previewTheme.isPreview = true;
      initFeed(locationIds, this.props.usernames, this.props.hashtags, this.el, previewTheme);
    }

    destroy() {
      destroyFeed(this.el);
    }

    reset(locations:Array<any>, usernames:Array<string>, hashtags:Array<string>, theme:any) {
      let previewTheme = {...theme};
      previewTheme.hideTitle = true;
      previewTheme.isPreview = true;
      let locationIds = [];
      for (let l of locations) {
        locationIds.push(l.id);
      }
      resetFeed(locationIds, usernames, hashtags, this.el, previewTheme);
    }

    render() {
      return (
        <div>
          {this.props.locations.length + this.props.usernames.length + this.props.hashtags.length === 0 && (
            <TextStyle variation="subdued">Please add an username, tag or location</TextStyle>
          )}
          {this.props.theme.showTitle && this.props.locations.length + this.props.usernames.length + this.props.hashtags.length > 0 && (
            <header className="section-header" style={{marginTop:'20px'}}>
              <h2 style={{textAlign: 'center', fontSize: '1.25em', textTransform: 'uppercase', fontWeight:'bold'}}>{this.props.title}</h2>
            </header>
          )}
          <div ref={el => this.el = el} />
        </div>
      );
    }
  }

  export default FeedPreview;